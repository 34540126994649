<template>
  <div class="InstrumentManagement contract-modal">
    <CSTabBar
        :checkedTab="checkedTab"
        :tabbar="orderState"
        class="tabBar"
        @changeTabBar="changeTabBar"
    />
    <div class="filter-panel">
      <input
          v-model="queryData.search"
          class="cs-input"
          placeholder="搜索仪表名称/编号/位置/备注"
          style="margin: 0; margin-bottom: 15px; width: 280px; height: 30px"
          type="text"
      />
      <CSSelect style="margin-left: 20px">
        <select
            v-model="queryData.species"
            style="width: 149px; padding: 0 5px"
        >
          <option style="width: 123px" value="">全部仪表种类</option>
          <option
              v-for="(name, type) in speciesList"
              :key="type"
              :value="type + 1"
          >
            {{ name }}
          </option>
        </select>
      </CSSelect>
      <CSSelect>
        <select v-model="queryData.type" style="width: 149px; padding: 0 5px">
          <option style="width: 123px" value="">全部仪表类型</option>
          <option
              v-for="(name, type) in typeList"
              :key="type"
              :value="type + 1"
          >
            {{ name }}
          </option>
        </select>
      </CSSelect>
      <div class="selectRoom">
        <QueryRoomSelect
            v-if="this.checkedTab == 2"
            :selected-rooms="roomIds"
            class="leftFloat"
            @change-rooms="queryRoomsSelect"
        >
        </QueryRoomSelect>
      </div>
      <button
          v-if="this.checkedTab == 2"
          class="btn btn-primary"
          style="position: absolute; left: 860px; top: 16px"
          type="button"
          @click="query()"
      >
        查询
      </button>
      <button
          v-if="this.checkedTab == 1"
          class="btn btn-primary"
          style="position: absolute; left: 688px; top: 16px"
          type="button"
          @click="query()"
      >
        查询
      </button>
    </div>
    <div class="result-panel">

      <!--    房间仪表-->
      <div v-if="checkedTab == 2">
        <div class="result-panel">
          <CSTable :thead-top="filterHeight">
            <template v-slot:header>
              <div class="table-header-panel text-right">
                <button class="btn btn-primary" type="button" @click="addHome()">
                  <svg aria-hidden="true" class="icon">
                    <use xlink:href="#icon-menujiahao"></use>
                  </svg>
                  仪表
                </button>
              </div>
            </template>
            <template v-slot:thead>
              <tr>
                <th class="left">仪表名称/编号</th>
                <th class="left">仪表位置</th>
                <th class="left">所在房间</th>
                <th>表箱号</th>
                <th>表组</th>
                <th>当前租客</th>
                <th>仪表种类</th>
                <th>仪表类型</th>
                <th>仪表量程</th>
                <th>初始表底</th>
                <th>最新表底</th>
                <th>备注</th>
                <th>操作</th>
              </tr>
            </template>
            <template v-slot:tbody>
              <tr v-for="(item, index) in homeData" :key="index">
                <td class="left">
                  {{ item.name }}
                </td>
                <td class="left">
                  {{ item.specificLocation }}
                </td>
                <td class="left">
                  {{ item.buildingName }}{{ item.roomCode }}
                </td>
                <td>{{ item.boxNum || '-' }}</td>
                <td>
                  {{ item.groupName }}
                </td>
                <td>
                                    <span
                                        v-if="item.lesseeName !== ''"
                                        class="allow-click"
                                        @click="lookPerson(item)"
                                    >
                                      查看
                                    </span>
                  <span v-else>-</span>
                </td>
                <td class="center">
                  {{
                    item.type == 1
                        ? "水表"
                        : item.type == 2
                            ? "电表"
                            : "燃气表"
                  }}
                </td>
                <td class="center">
                  {{
                    item.style == 1
                        ? "机械表"
                        : item.style == 2
                            ? "电子表"
                            : "智能表"
                  }}
                </td>
                <td class="center">
                  {{ item.rangeMin }}-{{ item.rangeMax }}
                </td>
                <td class="center">
                  {{ item.bottom }}
                </td>
                <td class="center">
                  <!-- 最新表底：暂时写初始表底， 有抄表记录的时候在修改 -->
                  <!-- {{item.bottom}} -->
                  {{ item.lastBottom }}
                </td>
                <td class="center">
                    <span
                        v-if="item.comment !== ''"
                        class="allow-click"
                        @click="lookContent(item.comment)"
                    >
                      查看
                    </span>
                  <span v-else>-</span>
                </td>
                <td class="center">
                  <div class="btn-group">
                    <button
                        aria-expanded="false"
                        aria-haspopup="true"
                        class="btn btn-primary dropdown-toggle"
                        data-toggle="dropdown"
                        type="button"
                    >
                      操作
                    </button>
                    <ul
                        class="dropdown-menu"
                        style="min-width: 76px; width: 76px"
                    >
                      <li
                          @click="
                            editInfo(
                              index,
                              item.lastBottom == item.bottom
                                ? 'no'
                                : item.lastBottom,
                              item
                            )
                          "
                      >
                        <a style="width: 100%">修改</a>
                      </li>
                      <li @click="deleteInfo(item.id)">
                        <a style="width: 100%">删除</a>
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>

            </template>
          </CSTable>
        </div>
      </div>
      <!--    公区仪表-->
      <div v-else>
        <div class="result-panel">
          <CSTable :thead-top="filterHeight">
            <template v-slot:header>
              <div class="table-header-panel text-right">
                <button class="btn btn-primary" type="button" @click="addPublic()">
                  <svg aria-hidden="true" class="icon">
                    <use xlink:href="#icon-menujiahao"></use>
                  </svg>
                  仪表
                </button>
              </div>
            </template>

            <template v-slot:thead>
              <tr>
                <th class="left">仪表名称/编号</th>
                <th class="left">仪表位置</th>
                <th>仪表种类</th>
                <th>仪表类型</th>
                <th>仪表量程</th>
                <th>仪表倍率</th>
                <th>初始表底</th>
                <th>最新表底</th>
                <th>备注</th>
                <th>操作</th>
              </tr>
            </template>
            <template v-slot:tbody>
              <tr v-for="(item, index) in publicData" :key="index">
                <td class="left">
                  {{ item.name }}
                </td>
                <td class="left">
                  {{ item.specificLocation }}
                </td>
                <td class="center">
                  {{
                    item.type == 1
                        ? "水表"
                        : item.type == 2
                            ? "电表"
                            : "燃气表"
                  }}
                </td>
                <td class="center">
                  {{
                    item.style == 1
                        ? "机械表"
                        : item.style == 2
                            ? "电子表"
                            : "智能表"
                  }}
                </td>
                <td class="center">
                  {{ item.rangeMin }}-{{ item.rangeMax }}
                </td>
                <td class="center">
                  {{ item.multiple }}
                </td>
                <td class="center">
                  {{ item.bottom }}
                </td>
                <td class="center">
                  <!-- 最新表底：暂时写初始表底， 有抄表记录的时候在修改 -->
                  {{ item.lastBottom }}
                </td>
                <td class="center">
                    <span
                        v-if="item.comment !== ''"
                        class="allow-click"
                        @click="lookContent(item.comment)"
                    >
                      查看
                    </span>
                  <span v-else class="noPerson"> </span>
                </td>
                <td class="center">
                  <div class="btn-group">
                    <button
                        aria-expanded="false"
                        aria-haspopup="true"
                        class="btn btn-primary dropdown-toggle"
                        data-toggle="dropdown"
                        type="button"
                    >
                      操作
                    </button>
                    <ul
                        class="dropdown-menu"
                        style="min-width: 76px; width: 76px"
                    >
                      <li
                          @click="
                            editInfo(
                              index,
                              item.lastBottom == item.bottom
                                ? 'no'
                                : item.lastBottom,
                              item
                            )
                          "
                      >
                        <a style="width: 100%">修改</a>
                      </li>
                      <li @click="deleteInfo(item.id)">
                        <a style="width: 100%">删除</a>
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
            </template>
          </CSTable>
        </div>

      </div>

      <Pagination component="Pagination" name="Pagination"></Pagination>
    </div>

    <!--    添加房间仪表对话框-->
    <CSDialog
        :dialogTitle="dialogTitle == 1 ? '添加仪表' : '修改仪表'"
        :dialogVisible="homeVisible"
        class="contract-modal"
        dialogConfirmBtnText="保存"
        dialogWidth="976px"
        style="
        box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
      "
        @onClose="homeClose"
        @onConfirm="addHomeSure"
    >
      <div slot="dialog-content" style="font-size: 15px; padding: 30px" @click="() => {divisionVisible = false}">
        <div class="dialog-form">
          <div class="dialog-form-item">
            <div class="dialog-form-item-label">仪表名称/编号</div>
            <div>
              <input
                  id="input"
                  v-model="addHomeInfo.id"
                  :disabled="addHomeInfo.isEdit"
                  autocomplete="off"
                  maxlength="20"
                  placeholder="限20个字"
                  type="text"
              />
            </div>
          </div>
          <div class="dialog-form-item">
            <div class="dialog-form-item-label">仪表位置</div>
            <div>
              <ChooseMeterLocation
                  ref="chooseMeterLocationRef"
                  :isEdit="addHomeInfo.isEdit"
                  @changeLocation="changeLocation"
              />
            </div>
          </div>
          <div class="dialog-form-item">
            <div class="dialog-form-item-label">所在房间</div>
            <div>
              <AllRoomSelect
                  ref="roomSelectRef"
                  :isEdit="addHomeInfo.isEdit"
                  class="roomSecect"
                  @change-rooms="changeContractRooms"
              >
              </AllRoomSelect>
            </div>
          </div>
          <div v-show="isSelectRoom" class="dialog-form-item">
            <div class="dialog-form-item-label">公司名称</div>
            <div>
              {{ addHomeInfo.name || "-" }}
            </div>
          </div>
          <div v-show="isSelectRoom" class="dialog-form-item">
            <div class="dialog-form-item-label">合同时间</div>
            <div>
              {{ addHomeInfo.time || "-" }}
            </div>
          </div>
          <div v-show="isSelectRoom" class="dialog-form-item">
            <div class="dialog-form-item-label">对接人</div>
            <div>
              {{ addHomeInfo.person || "-" }}
            </div>
          </div>
          <div class="dialog-form-item">
            <div class="dialog-form-item-label">仪表种类</div>
            <div>
              <CSSelect height="40px" i-width="36px" style="width: 235px">
                <select
                    v-model="addHomeInfo.species"
                    :disabled="addHomeInfo.isEdit"
                    class="select"
                >
                  <option value="">请选择</option>
                  <option
                      v-for="(name, type) in speciesList"
                      :key="type"
                      :value="type + 1"
                  >
                    {{ name }}
                  </option>
                </select>
              </CSSelect>
            </div>
          </div>
          <div class="dialog-form-item">
            <div class="dialog-form-item-label">仪表类型</div>
            <div>
              <CSSelect height="40px" i-width="36px" style="width: 235px">
                <select
                    v-model="addHomeInfo.type"
                    :disabled="addHomeInfo.isEdit"
                    class="select"
                >
                  <option value="">请选择</option>
                  <option
                      v-for="(name, type) in typeList"
                      :key="type"
                      :value="type + 1"
                  >
                    {{ name }}
                  </option>
                </select>
              </CSSelect>
            </div>
          </div>
          <div class="dialog-form-item">
            <div class="dialog-form-item-label">仪表量程</div>
            <div>
              <input
                  v-model="addHomeInfo.rangeStart"
                  :disabled="addHomeInfo.isEdit"
                  class="input"
                  placeholder="限整数"
                  type="number"
              />
              <span class="inline-gray"></span>
              <input
                  v-model="addHomeInfo.rangeEnd"
                  :disabled="addHomeInfo.isEdit"
                  class="input"
                  placeholder="限整数"
                  type="number"
              />
            </div>
          </div>

          <div class="dialog-form-item">
            <div class="dialog-form-item-label">初始表底</div>
            <div>
              <input
                  v-model="addHomeInfo.tableFoot"
                  :disabled="addHomeInfo.isEdit"
                  class="input"
                  placeholder="保留两位小数"
                  style="width: 235px;"
                  type="number"
              />
            </div>
          </div>

          <div class="dialog-form-item">
            <div class="dialog-form-item-label">表箱号</div>
            <div>
              <input
                  v-model="addHomeInfo.boxNum"
                  class="input"
                  placeholder="限20个字"
                  style="width: 400px"
                  type="text"
              />
            </div>
          </div>

          <div class="dialog-form-item">
            <div class="dialog-form-item-label">表组</div>
            <div
                class="field-item-content"
                style="position: relative;"
                @click.stop="
                                  () => {
                                      divisionVisible = !divisionVisible;
                                  }
                                "
            >
              <CSSelect height="40px" i-width="36px">
                <div
                    :style="{ width: '196px', padding: '0 6px' }"
                    class="field-font"
                >
                  <div v-if="addHomeInfo.groupId&&addHomeInfo.groupId.length > 0" :key="'abc'"
                  ><span style="color: black;font-size: inherit"
                  >已选择表组{{ addHomeInfo.groupId.length }}个</span
                  ></div
                  >
                  <div v-else>
                    <option style="font-size: inherit" value="">请选择</option>
                  </div>
                </div>
                <div>
                  <TreePanel

                      v-show="divisionVisible"
                      style="z-index: 100; overflow: scroll"
                  >
                    <div v-for="item in invoiceStatusList" :key="item.id"
                         class="roomli">
                      <input v-model="item.checkbox" class="selectDive roomMove"
                             type="checkbox" @click="selectRoom(item)"/>
                      <div>
                        {{ item.name }}
                      </div>
                    </div>
                  </TreePanel>
                </div>
              </CSSelect>
            </div>
          </div>

          <div class="dialog-form-item">
            <div class="dialog-form-item-label" style="height: 50px">倍率规则</div>
            <div>
              <CSRadio v-model="addHomeInfo.ratioRule" :items="{0:'用量=读数*倍率',1:'用量=读数/倍率'}"></CSRadio>
              <div style="font-size: 18px;color: #999">
                <svg v-if="addHomeInfo.species" aria-hidden="true" class="icon"
                     style="margin-right: 10px">
                  <use xlink:href="#icon-menua-zu92"></use>
                </svg>
                <span v-if="addHomeInfo.species == 1">水用量单位（吨）。</span>
                <span v-if="addHomeInfo.species == 2">电用量单位（度）。</span>
                <span v-if="addHomeInfo.species == 3">燃气用量单位（立方米）。</span>
              </div>
            </div>
          </div>

          <div class="dialog-form-item">
            <div class="dialog-form-item-label">倍率</div>
            <div>
              <input
                  v-model="addHomeInfo.ratio"
                  class="input"
                  placeholder="请输入"
                  type="number"
              />
            </div>
          </div>
          <div class="dialog-form-item">
            <div class="dialog-form-item-label" style="vertical-align: top">
              备注
            </div>
            <div style="width: 540px; display: inline-block">
              <textarea
                  v-model="addHomeInfo.comment"
                  class="textarea"
                  maxlength="200"
                  placeholder="限200个字,非必填"
                  type="text"
              ></textarea>
            </div>
          </div>
        </div>
      </div>
    </CSDialog>
    <!--    添加公区仪表对话框-->
    <CSDialog
        :dialogTitle="dialogTitle == 1 ? '添加仪表' : '修改仪表'"
        :dialogVisible="PublicVisible"
        dialogConfirmBtnText="保存"
        dialogWidth="976px"
        style="
        box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
      "
        @onClose="publicClose"
        @onConfirm="addPublicSure"
    >
      <div slot="dialog-content" style="font-size: 15px; padding: 30px">
        <div class="dialog-form">
          <div class="dialog-form-item">
            <div class="dialog-form-item-label">仪表名称/编号</div>
            <div>
              <input
                  id="input1"
                  v-model="addPublicInfo.id"
                  :disabled="addPublicInfo.isEdit"
                  maxlength="20"
                  placeholder="限20个字"
                  type="text"
              />
            </div>
          </div>
          <div class="dialog-form-item">
            <div class="dialog-form-item-label">仪表位置</div>
            <div>
              <ChooseMeterLocation
                  ref="chooseMeterLocationRef"
                  :isEdit="checkedTab == 2 ? false : false"
                  @changeLocation="changeLocation"
              />
            </div>
          </div>
          <div class="dialog-form-item">
            <div class="dialog-form-item-label">仪表种类</div>
            <div>
              <CSSelect height="40px" i-width="36px" style="width: 235px">
                <select
                    v-model="addPublicInfo.species"
                    :disabled="addPublicInfo.isEdit"
                    class="select"
                >
                  <option value="">请选择</option>
                  <option
                      v-for="(name, type) in speciesList"
                      :key="type"
                      :value="type + 1"
                  >
                    {{ name }}
                  </option>
                </select>
              </CSSelect>
            </div>
          </div>
          <div class="dialog-form-item">
            <div class="dialog-form-item-label">仪表类型</div>
            <div>
              <CSSelect height="40px" i-width="36px" style="width: 235px">
                <select
                    v-model="addPublicInfo.type"
                    :disabled="addPublicInfo.isEdit"
                    class="select"
                >
                  <option value="">请选择</option>
                  <option
                      v-for="(name, type) in typeList"
                      :key="type"
                      :value="type + 1"
                  >
                    {{ name }}
                  </option>
                </select>
              </CSSelect>
            </div>
          </div>
          <div class="dialog-form-item">
            <div class="dialog-form-item-label">仪表量程</div>
            <div>
              <input
                  v-model="addPublicInfo.rangeStart"
                  :disabled="addPublicInfo.isEdit"
                  class="input"
                  placeholder="限整数"
                  type="number"
              />
              <span class="inline-gray"></span>
              <input
                  v-model="addPublicInfo.rangeEnd"
                  :disabled="addPublicInfo.isEdit"
                  class="input"
                  placeholder="限整数"
                  type="number"
              />
            </div>
          </div>
          <div class="dialog-form-item">
            <div class="dialog-form-item-label">仪表倍率</div>
            <div>
              <input
                  v-model="addPublicInfo.ratio"
                  :disabled="addPublicInfo.isEdit"
                  class="input"
                  placeholder="限整数"
                  type="number"
              />
            </div>
          </div>
          <div class="dialog-form-item">
            <div class="dialog-form-item-label">初始表底</div>
            <div>
              <input
                  v-model="addPublicInfo.tableFoot"
                  :disabled="addPublicInfo.isEdit"
                  class="input"
                  placeholder="保留两位小数"
                  style="width: 235px;"
                  type="number"
              />
            </div>
          </div>
          <div class="dialog-form-item">
            <div class="dialog-form-item-label" style="vertical-align: top">
              备注
            </div>
            <div style="width: 540px; display: inline-block">
              <textarea
                  v-model="addPublicInfo.comment"
                  class="textarea"
                  maxlength="200"
                  placeholder="限200个字,非必填"
                  type="text"
              ></textarea>
            </div>
          </div>
        </div>
      </div>
    </CSDialog>
    <!-- 租客对话框 -->
    <CSDialog
        :dialogShowConfirmBtn="false"
        :dialogVisible="personVisible"
        dialog-header-class="alert-bg"
        dialogCancelBtnText="关闭"
        dialogTitle="当前租客"
        dialogWidth="620px"
        @onClose="CloseVisible"
    >
      <div slot="dialog-content" style="font-size: 15px; padding: 15px 30px">
        <div class="dialog-form lesseeName">
          <div class="dialog-form-item">
            <div class="dialog-form-item-label">企业/个人名称</div>
            <div>
              {{ currentHomeMeterInformation.lesseeName || "-" }}
            </div>
          </div>
          <div class="dialog-form-item">
            <div class="dialog-form-item-label">合同时间</div>
            <div>
              {{ currentHomeMeterInformation.contractPeriod || "-" }}
            </div>
          </div>
          <div class="dialog-form-item">
            <div class="dialog-form-item-label">对接人</div>
            <div>
              {{ currentHomeMeterInformation.deputyName || "-" }}
            </div>
          </div>
          <div class="dialog-form-item">
            <div class="dialog-form-item-label">手机号</div>
            <div>
              {{ currentHomeMeterInformation.deputyPhone || "-" }}
            </div>
          </div>
        </div>
      </div>
    </CSDialog>
    <!-- 备注对话框 -->
    <CSDialog
        :dialogShowConfirmBtn="false"
        :dialogVisible="contentVisible"
        dialog-header-class="alert-bg"
        dialogCancelBtnText="关闭"
        dialogTitle="备注"
        dialogWidth="540px"
        @onClose="CloseVisible"
    >
      <div slot="dialog-content" style="font-size: 15px; padding: 15px 30px">
        <div v-if="this.checkedTab == 2" class="content">
          {{ addHomeInfo.comment }}
        </div>
        <div v-else class="content">
          {{ addPublicInfo.comment }}
        </div>
      </div>
    </CSDialog>
  </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import ChooseMeterLocation from "@/components/ChooseMeterLocation";
import AllRoomSelect from "@/components/AllRoomSelect.vue";
import QueryRoomSelect from "@/views/SmartMeter/QueryRoomSelect.vue";
import CSTabBar from "@/components/common/CSTabBar";
import CSSelect from "@/components/common/CSSelect";
import CSDialog from "@/components/common/CSDialog";
import TreePanel from "@/components/common/TreePanel";
import CSTable from "@/components/common/CSTable";
import {
  addInstrumentUrl,
  deleteInstrumentUrl,
  editInstrumentUrl,
  queryInstrumentUrl,
  queryMeterGroupUrl,
  querySimilarLocationUrl,
} from "@/requestUrl";
import CSRadio from "@/components/common/CSRadio";

export default {
  name: "InstrumentManagement",
  components: {
    CSRadio,
    CSTable,
    CSTabBar,
    CSSelect,
    CSDialog,
    AllRoomSelect,
    ChooseMeterLocation,
    QueryRoomSelect,
    Pagination,
    TreePanel
  },
  created() {

  },
  data() {
    return {
      forId: '',
      filterHeight: 0,
      divisionV: 1,
      divisionVisible: false,
      invoiceStatusList: [],//全部表组
      isLoadings: true,
      isLoading: true,
      isSelectRoom: false, //跟随是否选择房间显示
      orderState: {
        1: "公区仪表",
        2: "房间仪表"
      },
      checkedTab: 1, //根据orderState显示tab-content
      //查询仪表数据
      queryData: {
        search: "", //选填
        type: "", //类型
        species: "", //种类
        home: "", //房间
        pageNo: 1, //为空查询全部
        pageSize: 10,
      },
      //房间对话框
      homeVisible: false,
      dialogTitle: "", //对话框标题
      //添加房间信息
      addHomeInfo: {
        boxNum: "",// 表箱号
        groupId: [],//表组
        ratio: null,//倍率
        id: "", //名称、编号
        offset: "", //仪表位置
        buildingCode: "", //楼栋编号  必填
        specificLocation: "", //位置名
        buildingName: "", //楼栋名  必填
        buildingHomeName: "",
        floor: "", //楼层
        home: "", //所在房间
        name: "", //公司名称
        lesseeName: "", //租户名
        lesseeId: "", //租户ID
        time: "", //合同时间
        person: "", //对接人和手机
        personName: "", //对接人
        phone: "", //联系电话
        species: "", //种类
        type: "", //类型
        rangeStart: null, //最小量程
        rangeEnd: null, //最大量程
        tableFoot: null, //初始表底
        comment: "", //备注
        isEdit: false,
        ratioRule: 0//倍率规则
      },
      //修改房间信息的数据
      // editHomeInfo:{},
      //获取查询后的数据
      homeData: [],
      publicData: [],
      //仪表种类和类型数据
      speciesList: ["水表", "电表", "燃气表"],
      typeList: ["机械表", "电子表", "智能表"],
      // 公区对话框
      PublicVisible: false,
      //添加公区信息
      addPublicInfo: {
        id: "", //编号
        offset: "", //位置
        specificLocation: "", //位置名
        floor: "", //楼层
        species: "", //种类
        type: "", //类型
        buildingName: "", //楼栋名
        buildingCode: "", //楼栋编号  必填
        rangeStart: "",
        rangeEnd: "",
        ratio: "", //倍率
        tableFoot: "",
        meterBoxNo: '',//表箱号
        comment: "",
        isEdit: false,
      },
      personVisible: false, //租客对话框显隐
      contentVisible: false, //备注对话框显隐
      deleteVisible: false, //删除对话框显隐
      selectedRooms: [], //选中的租客房间信息
      currentHomeMeterInformation: {}, //当前房间仪表信息
      currentPublicMeterInformation: {}, //当前公区仪表信息
      homeQueryMeterReading: [], // 当前房间抄表记录
      publicQueryMeterReading: [], // 当前公区抄表记录
      locationList: [],
      roomIds: [], //选中的
    };
  },
  mounted() {
    this.getLocationList();
    this.query();
    this.$vc.on(this.$route.path, "pagination", "page_event", (currentPage) => {
      this.queryData.pageNo = currentPage;
      this.query(currentPage);
    });
  },

  methods: {
    //选择表组
    selectRoom(item) {
      setTimeout(() => {
        let arr = this.invoiceStatusList.filter(item => {
          return item.checkbox
        }).map(item => {
          return item.id
        }) || []
        this.addHomeInfo.groupId = arr;
        console.log(this.addHomeInfo.groupId);
      }, 200)
    },
    //查询全部表组
    queryInvoiceStatus() {
      return this.$fly.post(queryMeterGroupUrl, {
        search: '', //搜索框内容
        pageSize: '', //页面大小
        pageNo: '', //页码
        regionCode: this.$vc.getCurrentRegion().code, //区域编码
      }).then(res => {
        if (res.code != 0) {
          return
        }
        this.invoiceStatusList = res.data.datas.map(item => {
          return {
            ...item,
            checkbox: false
          }
        });
      })

    },
    //查询房间抄表记录
    // getQueryMeterReading (params) {
    //   this.$fly.post(queryMeterReading, params)
    //   .then((res)=>{
    //     this.homeQueryMeterReading=res.data
    //   })
    // },
    //选中的所有房间信息
    changeContractRooms(roomInfo) {
      const {roomIds, rooms} = roomInfo;
      // console.log(roomIds);
      if (roomIds.length) {
        this.isSelectRoom = true;
        rooms.forEach((item) => {
          // console.log(item);
          this.addHomeInfo.name = item.lesseeCompany ? item.lesseeCompany : "";
          this.addHomeInfo.time = item.contractDuration
              ? item.contractDuration
              : "";
          this.addHomeInfo.person =
              item.lesseeName && item.lesseePhone
                  ? `${item.lesseeName}【${item.lesseePhone}】`
                  : "";
          this.addHomeInfo.phone = item.lesseePhone ? item.lesseePhone : "";
          this.addHomeInfo.personName = item.lesseeName ? item.lesseeName : "";
          this.addHomeInfo.buildingHomeName = item.buildingName;
          this.addHomeInfo.home = item.roomNo ? item.roomNo : "";
          // this.addHomeInfo.specificLocation=item.floor
          this.addHomeInfo.lesseeName = item.lesseeCompany
              ? item.lesseeCompany
              : "";
          this.addHomeInfo.lesseeId = item.lesseeId ? item.lesseeId : "";
          // this.isSelectRoom=false
        });
      } else {
        this.addHomeInfo.name = "";
        this.addHomeInfo.time = "";
        this.addHomeInfo.person = "";
        this.addHomeInfo.phone = "";
        this.addHomeInfo.personName = "";
        // this.addHomeInfo.buildingName=item.buildingName
        this.addHomeInfo.home = "";
        // this.addHomeInfo.specificLocation=item.floor
        this.addHomeInfo.lesseeName = "";
        this.addHomeInfo.lesseeId = "";
        this.isSelectRoom = false;
      }
      this.$forceUpdate();
    },
    //获取仪表位置信息
    changeLocation(location) {
      if (this.checkedTab == 2) {
        this.addHomeInfo.floor = location.floor ? location.floor : "";
        this.addHomeInfo.offset = location.id;
        this.addHomeInfo.buildingCode = location.buildingId;
        this.addHomeInfo.buildingName = location.buildingName
            ? location.buildingName
            : "";
        this.addHomeInfo.specificLocation = location.specificLocation
            ? location.specificLocation
            : "";
      } else {
        this.addPublicInfo.floor = location.floor ? location.floor : "";
        this.addPublicInfo.offset = location.id;
        this.addPublicInfo.buildingCode = location.buildingId;
        this.addPublicInfo.buildingName = location.buildingName
            ? location.buildingName
            : "";
        this.addPublicInfo.specificLocation = location.specificLocation
            ? location.specificLocation
            : "";
      }
    },
    //获取查询房间数据
    queryRoomsSelect(roomInfo) {
      console.log(roomInfo);
      const {roomIds, rooms} = roomInfo;
      if (roomIds.length) {
        rooms.forEach((item) => {
          this.queryData.home = item.roomNo;
        });
      } else {
        this.queryData.home = "";
      }
    },
    //改变tabBar
    changeTabBar(tab) {
      this.checkedTab = tab;
      this.queryData.pageNo = 1;
      Object.keys(this.queryData).forEach((key) => {
        this.queryData[key] = "";
      });
      this.query();
    },
    // 查询仪表
    query(pageNo = this.queryData.pageNo || 1, pageSize = 10) {
      //查询房间数据
      if (this.checkedTab == 2) {
        let meterInfo = this.queryData.search.concat(this.queryData.home);
        let params = {
          meterInfo,
          category: 1,
          type: this.queryData.species || "",
          style: this.queryData.type || "",
          pageNo,
          pageSize,
        };
        this.$fly.post(queryInstrumentUrl, params).then((res) => {
          this.homeData = res.data.datas;
          this.isLoading = false;
          if (pageNo === 1) {
            this.$vc.emit(this.$route.path, "pagination", "init", {
              total: res.data.total,
              currentPage: pageNo,
              pageSize,
            });
          }
        });
      } else {
        //查询公区数据
        let params = {
          meterInfo: this.queryData.search,
          category: 2,
          type: this.queryData.species || "",
          style: this.queryData.type || "",
          pageNo,
          pageSize,
        };
        this.$fly.post(queryInstrumentUrl, params).then((res) => {
          this.publicData = res.data.datas;
          this.isLoadings = false;
          if (pageNo === 1) {
            this.$vc.emit(this.$route.path, "pagination", "init", {
              total: res.data.total,
              currentPage: pageNo,
              pageSize,
            });
          }
        });
      }
    },
    // 房间对话框显隐
    async addHome() {
      this.divisionVisible = false;
      await this.queryInvoiceStatus();
      this.$set(this.addHomeInfo, 'groupId', []);
      this.isSelectRoom = false;
      this.dialogTitle = 1;
      this.homeVisible = true;
      this.addHomeInfo.species = "";
      this.addHomeInfo.type = "";
    },
    //公区对话框显隐
    addPublic() {
      this.dialogTitle = 1;
      this.PublicVisible = true;
      this.addPublicInfo.species = "";
      this.addPublicInfo.type = "";
    },
    //关闭房间对话框
    homeClose() {
      this.homeVisible = false;
      this.addHomeInfo = {};
      this.addHomeInfo.ratioRule = 0;
    },
    //添加/修改房间仪表
    addHomeSure() {
      if (!this.formValidate()) {
        this.$vc.toast(this.$vc.validate.errInfo);
        return;
      }
      let {
        id,
        offset,
        buildingCode,
        specificLocation,
        buildingName,
        buildingHomeName,
        floor,
        home,
        lesseeName,
        lesseeId,
        time,
        personName,
        phone,
        species,
        type,
        rangeStart,
        rangeEnd,
        tableFoot,
        comment,
        boxNum,
        groupId,
        ratioRule,
        ratio
      } = this.addHomeInfo;
      //添加
      // let connectSpecificLocation=`${buildingName}${floor}${specificLocation}`
      floor = floor ? `${floor}层` : "";
      if (this.dialogTitle == 1) {
        console.log('添加');
        let params = {
          id: null,
          name: id,
          locationCode: offset.toString(),
          buildingCode,
          specificLocation: `${buildingName}${floor}${specificLocation}`,
          buildingName: buildingHomeName,
          roomCode: home,
          lesseeName,
          lesseeId,
          contractPeriod: time,
          deputyName: personName,
          deputyPhone: phone,
          type: species,
          style: type,
          category: 1,
          rangeMin: +rangeStart,
          rangeMax: +rangeEnd,
          bottom: +tableFoot,
          multiple: 1,
          comment: comment,
          boxNum,
          groupId: groupId?.toString(),
          ratioRule,
          ratio: +ratio
        };
        this.$fly.post(addInstrumentUrl, params).then((res) => {
          if (res.code == 0) {
            this.$vc.toast("添加成功");
            this.query();
            this.homeVisible = false;
            this.addHomeInfo = {};
            this.addHomeInfo.ratioRule = 0;
          }
          this.homeVisible = false;
        });
      } else {
        console.log('this.currentHomeMeterInformation.id');
        console.log(this.currentHomeMeterInformation.id);
        //修改
        let updataParams = {
          id: this.currentHomeMeterInformation.id,
          name: id,
          locationCode: offset,
          buildingCode,
          specificLocation: `${buildingName}${floor}${specificLocation}`,
          buildingName: buildingHomeName,
          roomCode: home,
          lesseeName,
          lesseeId,
          contractPeriod: time,
          deputyName: personName,
          deputyPhone: phone,
          type: species,
          style: type,
          category: 1,
          rangeMin: rangeStart,
          rangeMax: rangeEnd,
          bottom: tableFoot,
          multiple: 1,
          comment: comment,
          ratioRule: ratioRule,
          ratio: ratio,
          groupId: groupId?.toString(),
        };
        this.$fly
            .post(editInstrumentUrl, updataParams)

            .then((res) => {
              if (res.code == 0) {
                this.homeVisible = false;
                this.addHomeInfo = {};
                this.addHomeInfo.ratioRule = 0;
                this.$vc.toast("修改成功");
                this.query();
              }
              this.homeVisible = false;
            });
      }
    },
    // 关闭公区对话框
    publicClose() {
      this.PublicVisible = false;
      this.addPublicInfo = {};
    },
    //添加/修改公区仪表
    addPublicSure() {
      if (!this.formValidate()) {
        this.$vc.toast(this.$vc.validate.errInfo);
        return;
      }
      let {
        id,
        offset,
        buildingName,
        species,
        type,
        buildingCode,
        specificLocation,
        floor,
        rangeStart,
        rangeEnd,
        ratio,
        tableFoot,
        comment,
      } = this.addPublicInfo;
      floor = floor ? `${floor}层` : "";
      if (this.dialogTitle == 1) {
        let params = {
          name: id,
          locationCode: offset,
          buildingCode,
          specificLocation: `${buildingName}${floor}${specificLocation}`,
          buildingName,
          type: species,
          style: type,
          category: 2,
          rangeMin: rangeStart,
          rangeMax: rangeEnd,
          bottom: tableFoot,
          multiple: ratio,
          comment,
        };
        this.$fly.post(addInstrumentUrl, params).then((res) => {
          if (res.code == 0) {
            this.$vc.toast("添加成功");
            this.query();
            this.PublicVisible = false;
            this.addPublicInfo = {};
          }
          this.PublicVisible = false;
        });
      } else {
        //修改
        let updataParams = {
          id: this.forId,
          name: id,
          locationCode: offset,
          buildingCode,
          specificLocation: `${buildingName}${floor}${specificLocation}`,
          buildingName,
          type: species,
          style: type,
          category: 2,
          rangeMin: rangeStart,
          rangeMax: rangeEnd,
          bottom: tableFoot,
          multiple: ratio,
          comment,
        };
        this.$fly
            .post(editInstrumentUrl, updataParams)

            .then((res) => {
              if (res.code == 0) {
                this.publicClose();
                this.addPublicInfo = {};
                this.$vc.toast("修改成功");
                this.query();
              }
              this.publicClose();
            });
      }
    },
    //表单验证
    formValidate() {
      if (this.checkedTab == 2) {
        return this.$vc.validate.validate(
            {
              addHomeInfo: this.addHomeInfo,
            },
            {
              "addHomeInfo.id": [
                {
                  limit: "required",
                  param: "",
                  errInfo: "仪表名称/编号不能为空",
                },
              ],
              "addHomeInfo.offset": [
                {
                  limit: "required",
                  param: "",
                  errInfo: "仪表位置不能为空",
                },
              ],
              "addHomeInfo.home": [
                {
                  limit: "required",
                  param: "",
                  errInfo: "所在房间不能为空",
                },
              ],
              "addHomeInfo.species": [
                {
                  limit: "required",
                  param: "",
                  errInfo: "仪表种类不能为空",
                },
              ],
              "addHomeInfo.type": [
                {
                  limit: "required",
                  param: "",
                  errInfo: "仪表类型不能为空",
                },
              ],
              "addHomeInfo.rangeStart": [
                {
                  limit: "required",
                  param: "",
                  errInfo: "仪表量程不能为空",
                },
                {
                  limit: "num",
                  param: "",
                  errInfo: "仪表量程只能填正整数",
                },
              ],
              "addHomeInfo.rangeEnd": [
                {
                  limit: "required",
                  param: "",
                  errInfo: "仪表量程不能为空",
                },
                {
                  limit: "num",
                  param: "",
                  errInfo: "仪表量程只能填正整数",
                },
              ],
              "addHomeInfo.tableFoot": [
                {
                  limit: "required",
                  param: "",
                  errInfo: "初始表底不能为空",
                },
                {
                  limit: "money",
                  param: "",
                  errInfo: "初始表底保留两位小数",
                },
              ],
            }
        );
      } else {
        return this.$vc.validate.validate(
            {
              addPublicInfo: this.addPublicInfo,
            },
            {
              "addPublicInfo.id": [
                {
                  limit: "required",
                  param: "",
                  errInfo: "仪表名称/编号不能为空",
                },
              ],
              "addPublicInfo.offset": [
                {
                  limit: "required",
                  param: "",
                  errInfo: "仪表位置不能为空",
                },
              ],
              "addPublicInfo.species": [
                {
                  limit: "required",
                  param: "",
                  errInfo: "仪表种类不能为空",
                },
              ],
              "addPublicInfo.type": [
                {
                  limit: "required",
                  param: "",
                  errInfo: "仪表类型不能为空",
                },
              ],
              "addPublicInfo.rangeStart": [
                {
                  limit: "required",
                  param: "",
                  errInfo: "仪表量程不能为空",
                },
                {
                  limit: "money",
                  param: "",
                  errInfo: "仪表量程只能填整数",
                },
              ],
              "addPublicInfo.rangeEnd": [
                {
                  limit: "required",
                  param: "",
                  errInfo: "仪表量程不能为空",
                },
                {
                  limit: "num",
                  param: "",
                  errInfo: "仪表量程只能填整数",
                },
              ],
              "addPublicInfo.tableFoot": [
                {
                  limit: "required",
                  param: "",
                  errInfo: "初始表底不能为空",
                },
                {
                  limit: "money",
                  param: "",
                  errInfo: "初始表底保留两位小数",
                },
              ],
            }
        );
      }
    },
    //查看租客
    lookPerson(item) {
      this.personVisible = true;
      this.currentHomeMeterInformation = item;
    },
    //查看备注
    lookContent(comment) {
      this.contentVisible = true;
      if (this.checkedTab == 2) {
        this.addHomeInfo.comment = comment;
      } else {
        this.addPublicInfo.comment = comment;
      }
    },
    // 修改信息
    async editInfo(id, lastBottom, item) {
      this.isSelectRoom = false;
      await this.queryInvoiceStatus();
      this.currentHomeMeterInformation = item;
      if (this.checkedTab == 2) {
        this.homeVisible = true;
        this.dialogTitle = 2;
        //将存在的数据显示
        let homeInfo = item;
        console.log('数据列表');
        console.log(homeInfo);
        this.addHomeInfo = {
          id: homeInfo.name,
          offset: homeInfo.locationCode,
          buildingCode: homeInfo.buildingCode,
          specificLocation: homeInfo.specificLocation,
          buildingName: homeInfo.buildingHomeName,
          home: homeInfo.roomCode,
          name: homeInfo.lesseeName ? homeInfo.lesseeName : "",
          lesseeName: homeInfo.lesseeName,
          lesseeId: homeInfo.lesseeId,
          time: homeInfo.contractPeriod,
          person: homeInfo.deputyName ? homeInfo.deputyName : "",
          species: homeInfo.type,
          type: homeInfo.style,
          rangeStart: homeInfo.rangeMin,
          rangeEnd: homeInfo.rangeMax,
          tableFoot: homeInfo.bottom,
          comment: homeInfo.comment,
          boxNum: homeInfo.boxNum,
          ratioRule: homeInfo.ratioRule,
          ratio: homeInfo.ratio,
          groupId: homeInfo.groupId ? homeInfo.groupId.split(',') : []
        };
        console.log('修改数据');
        homeInfo.specificLocation = this.addHomeInfo.specificLocation.replace(new RegExp(homeInfo.buildingName, 'g'), '');
        homeInfo.buildingName = "";
        this.invoiceStatusList = this.invoiceStatusList.map(item => {
          for (let i = 0; i < this.addHomeInfo.groupId.length; i++) {
            if (+this.addHomeInfo.groupId[i] == item.id) {

              return {
                ...item,
                checkbox: true
              }
            }
          }
          return {
            ...item,
            checkbox: false
          }
        })
        if (homeInfo.lesseeName !== "") {
          this.isSelectRoom = true;
        }
        // this.editHomeInfo=this.addHomeInfo.id=
        this.$nextTick(() => {
          this.$refs.chooseMeterLocationRef.changeLocation(homeInfo);
          this.$refs.roomSelectRef.setBuildingName(homeInfo);
        });
        if (lastBottom !== "no") {
          this.addHomeInfo.isEdit = true;
        }

        //查询抄表记录
        //  let info={
        //    category:this.currentHomeMeterInformation.category,
        //    style:this.currentHomeMeterInformation.style,
        //    type:this.currentHomeMeterInformation.type,
        //    pageNo:1,
        //    pageSize:10
        //  }
        //  this.getQueryMeterReading(info)
      } else {
        this.forId = item.id;
        this.PublicVisible = true;
        this.dialogTitle = 2;
        //将存在的数据显示
        let publicInfo = item;
        this.addPublicInfo = {
          id: publicInfo.name,
          offset: publicInfo.locationCode,
          buildingCode: publicInfo.buildingCode,
          specificLocation: publicInfo.specificLocation,
          buildingName: publicInfo.buildingName,
          ratio: publicInfo.multiple,
          species: publicInfo.type,
          type: publicInfo.style,
          rangeStart: publicInfo.rangeMin,
          rangeEnd: publicInfo.rangeMax,
          tableFoot: publicInfo.bottom,
          comment: publicInfo.comment,
        };
        publicInfo.specificLocation = this.addPublicInfo.specificLocation.replace(new RegExp(publicInfo.buildingName, 'g'), '');
        // publicInfo.buildingName = "";
        this.$nextTick(() => {
          this.$refs.chooseMeterLocationRef.changeLocation(publicInfo);
        });
        if (lastBottom !== "no") {
          this.addPublicInfo.isEdit = true;
        }
        //查询抄表记录
        //  let info={
        //    category:this.currentPublicMeterInformation.category,
        //    style:this.currentPublicMeterInformation.style,
        //    type:this.currentPublicMeterInformation.type,
        //    pageNo:1,
        //    pageSize:10
        //  }
        //  this.getQueryPublicMeterReading(info)
      }
    },
    //删除信息
    deleteInfo(id) {
      this.$CSDialog.confirm({
        title: "提示",
        message: "确定删除吗?",
        onConfirm: () => {

          this.$fly.get(`${deleteInstrumentUrl}?id=${id}`).then((res) => {
            if (res.code != 0) {
              // this.$vc.toast("删除失败");
              return;
            }
            this.$vc.toast("删除成功");
            this.$CSDialog.instance.closeDialog();
            this.query();
          });
        }
      });
    },
    // 关闭对话框
    CloseVisible() {
      this.personVisible = false;
      this.contentVisible = false;
      this.deleteVisible = false;
    },
    /**
     * 获取位置列表
     * @param {String} specificLocation 相似位置
     * */
    getLocationList(specificLocation = "") {
      this.$fly
          .post(querySimilarLocationUrl, {
            regionCode: this.$vc.getCurrentRegion().code,
            specificLocation,
          })
          .then((res) => {
            if (res.code !== 0) {
              return;
            }
            this.locationList = res.data;
          });
    },

    disposeLocation(id) {
      const location = this.locationList.find((item) => item.id == id);
      return location ? location.specificLocation : "";
    },
  },
};
</script>

<style lang="stylus" scoped>
th, td {
  text-align center !important
}

.filter-panel {
  position: relative;
}

select.selected {
  color: black !important;
  opacity: 1 !important;
}

input:disabled {
  color: black !important;
  background-color: #f0f0f0;
}

#input {
  width: 400px;
  height: 40px;
  padding-left: 10px;
  border: 1px solid #979797;
  border-radius: 4px;
  vertical-align: middle;
}

.wrapper-loading {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dialog-form {
  padding: 27px 30px;
  font-size: 24px;
  color: #000;

  &-item {
    &:not(:last-of-type) {
      margin-bottom: 23px;
    }

    &-label {
      width: 156px;
      height: 33px;
      text-align: right;
      margin-right: 40px;
      display: inline-block;
      vertical-align: middle;
      line-height: 33px;
    }

    & > div {
      display: inline-block;
      vertical-align: middle;

      input {
        &::placeholder {
          color: #999;
        }

        width: 140px;
        height: 40px;
        border-radius: 4px;
        padding: 0 5px;
        border: 1px solid #979797;
        vertical-align: middle;
        padding-left: 10px;
      }
    }
  }
}

.select {
  padding-left: 6px;
}

.textarea {
  width: 690px;
  background-color: #f0f0f0;
  border: 1px solid #f0f0f0;
  border-radius: 10px;
  height: 200px;
  font-size: 24px;
  resize: none;
  padding-left: 10px;
  margin-right: 30px;
}

#input1 {
  width: 400px;
  height: 40px;
  padding-left: 10px;
  border: 1px solid #979797;
  border-radius: 4px;
  vertical-align: middle;
}

.content {
  font-size: 24px;
  display: flex;
  justify-content: center;
}

.selectRoom {
  width: 200px;
}

.noPerson {
  display: inline-block;
  border-top: 1px solid #000;
  height: 1px;
  width: 8px;
}

.loading {
  animation: identifier 2s linear 0s infinite;
  width: 1px;
  background-color: black;
  height: 15px;
  margin-left: 5px;
}

.loading2 {
  animation: identifier 2s linear 0.1s infinite;
}

.loading3 {
  animation: identifier 2s linear 0.2s infinite;
}

.loading4 {
  animation: identifier 2s linear 0.3s infinite;
}

.loading5 {
  animation: identifier 2s linear 0.4s infinite;
}

.wrapper-loading {
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes identifier {
  0% {
    height: 15px;
  }

  25% {
    height: 0px;
  }

  50% {
    height: 15px;
  }

  75% {
    height: 0px;
  }

  100% {
    height: 15px;
  }
}

.noRecord {
  width: 100px;
  margin: 0 auto;
}

td {
  text-align center !important
}

th {
  text-align center !important
}

.left {
  text-align: center !important;
  max-width 200px !important;
}

.lesseeName {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

input[type='checkbox'] {
  width: 0px !important
  display inline-block
  opacity 1
  margin-right 25px
  height 20px !important
}

.roomli {
  display flex
  align-items center
}
</style>
